/* eslint-disable no-unused-vars */
import React from 'react';
import { FieldArray, ErrorMessage } from 'formik';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const checkDimensions = (file) =>
    new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            // Allow dimensions 800x400 or lower
            if (img.width <= 800 && img.height <= 400) {
                resolve(true); // Dimensions are valid
            } else {
                resolve(false); // Dimensions are invalid
            }
        };

        img.onerror = () => {
            reject(new Error("Failed to load the image."));
        };

        const reader = new FileReader();
        reader.onload = (event) => {
            img.src = event.target.result; // Load the image
        };

        reader.onerror = () => {
            reject(new Error("Failed to read the file."));
        };

        reader.readAsDataURL(file);
    });


export default function StepSevenUpdate({ values, setFieldValue, touched, errors }) {
    const [errorTexts, setErrorTexts] = React.useState({});

    const handleFileUpload = async (event, index) => {
        const file = event.currentTarget.files[0];
        const updatedErrorTexts = { ...errorTexts };

        try {
            const isValid = await checkDimensions(file);
            if (!isValid) {
                updatedErrorTexts[index] = "The image dimensions do not match 800x400 or lower.";
                event.target.value = null; // Reset the file input
                setFieldValue(`uploadedFile[${index}]`, null);
            } else {
                updatedErrorTexts[index] = null;
                const updatedFiles = [...values.uploadedFile];
                updatedFiles[index] = file;
                setFieldValue("uploadedFile", updatedFiles);
            }
        } catch (error) {
            updatedErrorTexts[index] = "Failed to load the image.";
            event.target.value = null; // Reset the file input
            setFieldValue(`uploadedFile[${index}]`, null);
        }

        setErrorTexts(updatedErrorTexts);
    };

    const handleSignatureUpload = (event) => {
        const file = event.currentTarget.files[0];
        setFieldValue("signature", file);
    };

    return (
        <>
            <div className="card card-dark p-3 m-3">
                <div className="card-header">
                    <h3 className="card-title">Upload Documents</h3>
                </div>
                <Box className="card-body" sx={{ padding: "2rem 0" }}>
                    <Grid container spacing={2}>
                        {/* Document Uploads */}
                        <FieldArray
                            name="uploadedFile"
                            render={(arrayHelpers) => {
                                const reversedFiles = [...values.uploadedFile].reverse();

                                return (
                                    <>
                                        {reversedFiles && reversedFiles.length > 0 ? (
                                            reversedFiles.map((file, revIndex) => {
                                                const originalIndex =
                                                    values.uploadedFile.length - 1 - revIndex;

                                                return (
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        key={originalIndex}
                                                        alignItems="center"
                                                    >
                                                        <Grid item md={10} xs={10}>
                                                            <Box
                                                                sx={{
                                                                    border: "1px solid grey",
                                                                    borderRadius: "8px",
                                                                    p: 2,
                                                                    marginBottom: 2,
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    gutterBottom
                                                                    align="left"
                                                                >
                                                                    Upload Document {originalIndex + 1}
                                                                </Typography>
                                                                <input
                                                                    type="file"
                                                                    onChange={(event) =>
                                                                        handleFileUpload(event, originalIndex)
                                                                    }
                                                                />
                                                                {file && (
                                                                    <>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textSecondary"
                                                                        >
                                                                            {file.name}
                                                                        </Typography>
                                                                        <Box
                                                                            sx={{
                                                                                mt: 2,
                                                                                border: "1px solid #ddd",
                                                                                borderRadius: "8px",
                                                                                overflow: "hidden",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={URL.createObjectURL(file)}
                                                                                alt={`Preview ${file.name}`}
                                                                                style={{
                                                                                    width: "100%",
                                                                                    maxWidth: "200px",
                                                                                    marginTop: "10px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                                <ErrorMessage
                                                                    name={`uploadedFile[${originalIndex}]`}
                                                                    component="div"
                                                                    style={{
                                                                        color: "red",
                                                                        marginTop: "8px",
                                                                    }}
                                                                />
                                                                {errorTexts[originalIndex] && (
                                                                    <Typography variant="body2" color="error">
                                                                        {errorTexts[originalIndex]}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={2}
                                                            xs={2}
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(originalIndex)
                                                                }
                                                                disabled={values.uploadedFile.length === 1} // Disable delete if only one file input remains
                                                                sx={{ padding: 0 }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })
                                        ) : (
                                            <Grid container spacing={2}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    sx={{ paddingLeft: 2 }}
                                                >
                                                    No files uploaded yet.
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid container spacing={2} justifyContent="flex-end">
                                            <IconButton
                                                aria-label="add"
                                                onClick={() => arrayHelpers.push(null)} // Adds a new file upload field
                                                sx={{ padding: 0 }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </>
                                );
                            }}
                        />

                        {/* Signature Upload */}
                        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                            <Box
                                sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}
                            >
                                <Typography variant="h6" gutterBottom align="left">
                                    Upload Signature
                                </Typography>
                                <input
                                    type="file"
                                    onChange={handleSignatureUpload}
                                />
                                {values.signature && (
                                    <>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{ mt: 1 }}
                                        >
                                            {values.signature.name}
                                        </Typography>
                                        <Box
                                            sx={{
                                                mt: 2,
                                                border: "1px solid #ddd",
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <img
                                                src={URL.createObjectURL(values.signature)}
                                                alt={`Signature Preview`}
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "200px",
                                                    marginTop: "10px",
                                                }}
                                            />
                                        </Box>
                                    </>
                                )}
                                <ErrorMessage
                                    name="signature"
                                    component="div"
                                    style={{ color: "red", marginTop: "8px" }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}

