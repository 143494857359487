import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Dropmenu, Search } from '../../Components';
import { Link, useNavigate, } from "react-router-dom";
import { useGetQuotationsQuery } from '../../Redux/Service/QuotationApi';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { API_URL } from '../../Config';
import Loading from '../../Components/Loading';
import PostAddIcon from '@mui/icons-material/PostAdd';

function QuotationList() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [quotationData, setQuotationData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data: quotations, refetch, isLoading, isError, error } = useGetQuotationsQuery({
        limit: rowsPerPage,
        page: page - 1,
        orderBy: 'DESC',
        search: searchQuery
    });

    const customStyles = {
        rows: {
            style: {
                margin: '0px',
                padding: 0
            },
        },
        headCells: {
            style: {
                margin: '0px',
                padding: '10px 15px',
                fontWeight: 600,
                fontSize: 15,
                background: '#f3f3f3',
            },
        },
        cells: {
            style: {
                margin: '0px',
                padding: '10px 15px',
                fontSize: 15,
            },
        },
    };

    useEffect(() => {
        refetch();
    }, [refetch, searchQuery]);

    useEffect(() => {
        if (!isLoading && !isError) {
            setQuotationData(quotations?.response);
            setLoading(false);
        }
    }, [isLoading, isError, quotations]);

    const handleEdit = (id) => {
        navigate(`/quotation-edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/quotation/${id}`);
    }

    const handleSearch = (search) => {
        setSearchQuery(search);
        setPage(1);
    }

    const columns = [
        {
            name: 'SL No',
            cell: (row, index) => index + 1,
            button: false,
        },
        {
            name: 'Quotation No',
            selector: row => row.uniqueId,
        },
        {
            name: 'Name',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: 'Created By',
            selector: row => row.user && row.user?.name,
            sortable: true,
        },
        {
            name: 'Estimate',
            selector: row => row.estimate && row.estimate?.name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => new Date(row.date).toDateString(),
            sortable: true,
        },
        {
            name: 'Total',
            selector: row =>
                <div>
                    <b>
                        {row.currency?.symbol ?? 'USD'} {Number(row.total).toLocaleString()}
                    </b>
                </div>,
            sortable: true,
        },
        {
            name: 'PDF',
            selector: row =>
                <div>
                    <Link className="dark-link" to={API_URL + row.pdfPath} target="_blank" download ><CloudDownloadIcon /></Link>
                </div>
        },
        {
            name: 'Status',
            selector: row => row.quotationStatus?.name ?? 'ON Holding'
        },
        {
            name: 'Action',
            cell: (row) => (
                <div>
                    <Dropmenu className="dark-link" handleEdit={() => handleEdit(row.id)} handleView={() => handleView(row.id)} />
                </div>
            ),
            button: false,
        }
    ];

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="">
                            <h1 className="m-0 badge badge-dark">Quotations</h1>
                        </div>
                        <div className="">
                            <Link to="/quotation-add" className="btn btn-dark"><PostAddIcon /> Add Quotation</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card m-3 card-outline card-dark">
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>Error: {error?.message}</div>
                ) : (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}></Grid>
                            <Grid item md={6} xs={12} display='flex' justifyContent='flex-end'>
                                <Search onSearch={(query) => handleSearch(query)} />
                            </Grid>
                        </Grid>

                        <DataTable
                            columns={columns}
                            data={quotationData.data}
                            pagination
                            paginationServer
                            paginationTotalRows={quotationData.pagination.total}
                            paginationDefaultPage={page}
                            paginationPerPage={rowsPerPage}
                            onChangePage={setPage}
                            onChangeRowsPerPage={(newPerPage) => {
                                setRowsPerPage(newPerPage);
                                setPage(1);
                            }}
                            customStyles={customStyles}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default QuotationList;
