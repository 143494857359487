import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Dropmenu, Search } from '../../Components';
import { Link, useNavigate, } from "react-router-dom";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { API_URL } from '../../Config';
import Loading from '../../Components/Loading';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useGetEstimationQuery } from '../../Redux/Service/EstimateApi';

function EstimateList() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [estimationData, setEstimationData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data: estimation, refetch, isLoading, isError, error } = useGetEstimationQuery({
        limit: rowsPerPage,
        page: page - 1,
        orderBy: 'DESC',
        search: searchQuery
    });

    const customStyles = {
        rows: {
            style: {
                margin: '0px',
                padding: 0
            },
        },
        headCells: {
            style: {
                margin: '0px',
                padding: '10px 15px',
                fontWeight: 600,
                fontSize: 15,
                background: '#f3f3f3',
            },
        },
        cells: {
            style: {
                margin: '0px',
                padding: '10px 15px',
                fontSize: 15,
            },
        },
    };

    useEffect(() => {
        refetch();
    }, [refetch, searchQuery]);

    useEffect(() => {
        if (!isLoading && !isError) {
            setEstimationData(estimation?.response);
            setLoading(false);
        }
    }, [isLoading, isError, estimation]);

    const handleEdit = (id) => {
        navigate(`/estimation-edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/estimation/${id}`);
    }

    const handleSearch = (search) => {
        setSearchQuery(search);
        setPage(1);
    }

    const columns = [
        {
            name: 'SL No',
            cell: (row, index) => index + 1,
            button: false,
        },
        {
            name: 'Name',
            selector: row => row.name && row.name,
            sortable: true,
        },
        {
            name: 'Prospect Name',
            selector: row => row.lead && row?.lead?.name,
            sortable: true,
        },
        {
            name: 'Created By',
            selector: row => row.user && row.user?.name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => new Date(row.createdAt).toDateString(),
            sortable: true,
        },
        {
            name: 'Total Hours',
            selector: row => row.totalHours ?? 0,
            sortable: true,
        },
        {
            name: 'Total Cost',
            selector: row => row.totalCost ?? 0,
            sortable: true,
            cell: row => <div>{row?.currency?.symbol} {row?.totalCost} </div>
        },
        {
            name: 'FILE',
            selector: row =>
                <div>

                    <Link className="dark-link" to={API_URL + row.file} target="_blank" download ><CloudDownloadIcon /></Link>
                </div>
        },
        // {
        //     name: 'Status',
        //     selector: row => row.status ?? 'Active'
        // },
        {
            name: 'Action',
            cell: (row) => (
                <div>
                    <Dropmenu className="dark-link" handleEdit={() => handleEdit(row.id)} handleView={() => handleView(row.id)} />
                </div>
            ),
            button: false,
        }
    ];

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="">
                            <h1 className="m-0 badge badge-dark">Estimation</h1>
                        </div>
                        <div className="">
                            <Link to="/estimate-add" className="btn btn-dark"><DocumentScannerIcon /> Add Estimation</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card m-3 card-outline card-dark">
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>Error: {error?.message}</div>
                ) : (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}></Grid>
                            <Grid item md={6} xs={12} display='flex' justifyContent='flex-end'>
                                <Search onSearch={(query) => handleSearch(query)} />
                            </Grid>
                        </Grid>

                        <DataTable
                            columns={columns}
                            data={estimationData.data}
                            pagination
                            paginationServer
                            paginationTotalRows={estimationData.pagination.total}
                            paginationDefaultPage={page}
                            paginationPerPage={rowsPerPage}
                            onChangePage={setPage}
                            onChangeRowsPerPage={(newPerPage) => {
                                setRowsPerPage(newPerPage);
                                setPage(1);
                            }}
                            customStyles={customStyles}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default EstimateList;
