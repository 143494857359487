import { FaUsers } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import PostAddIcon from '@mui/icons-material/PostAdd';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ArticleIcon from '@mui/icons-material/Article';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

function SideNav() {
    const location = useLocation();

    // Helper function to check if the current path is active
    const isActive = (path) => location.pathname.startsWith(path);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Link */}
            <Link to="/" className="brand-link">
                <span className="brand-text font-weight-light">PRE SALES</span>
            </Link>
            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Users */}
                        <li className="nav-item">
                            <Link to="/user" className={`nav-link ${isActive('/user') ? 'active' : ''}`}>
                                <FaUsers className="icon-size" />
                                <p> Users</p>
                            </Link>
                        </li>

                        {/* Leads */}
                        <li className="nav-item">
                            <Link to="/leads" className={`nav-link ${isActive('/leads') ? 'active' : ''}`}>
                                <LeaderboardIcon className="icon-size" />
                                <p> Leads</p>
                            </Link>
                        </li>

                        {/* Estimate */}
                        <li className="nav-item">
                            <Link to="/estimate" className={`nav-link ${isActive('/estimate') ? 'active' : ''}`}>
                                <DocumentScannerIcon className="icon-size" />
                                <p> Estimate</p>
                            </Link>
                        </li>

                        {/* Quotation */}
                        <li className="nav-item">
                            <Link to="/quotation" className={`nav-link ${isActive('/quotation') ? 'active' : ''}`}>
                                <PostAddIcon className="icon-size" />
                                <p> Quotation</p>
                            </Link>
                        </li>

                        {/* Contract */}
                        <li className="nav-item">
                            <Link to="/contract" className={`nav-link ${isActive('/contract') ? 'active' : ''}`}>
                                <ArticleIcon className="icon-size" />
                                <p> Contract</p>
                            </Link>
                        </li>

                    </ul>
                </nav>
            </div>
        </aside>
    );
}

export default SideNav;
