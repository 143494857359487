import { useState } from 'react';
import { Grid, TextField, Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomDatePicker from '../../Components/MUI/CustomDatePicker';
import { useGetEstimateListQuery } from '../../Redux/Service/EstimateApi';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useUpdateQuotationMutation, useFindQuotationQuery, useGetQuotationStatusQuery } from '../../Redux/Service/QuotationApi';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useGetCurrenciesQuery } from '../../Redux/Service/CurrencyApi';
import Loading from '../../Components/Loading';
import { API_URL } from '../../Config';

function QuotationEdit() {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const [updateQuotation] = useUpdateQuotationMutation();
    const [imagePreview, setImagePreview] = useState(null);
    const [symbol, setSymbol] = useState('USD');
    const { data: quotationData, isLoading: isLoadingForQuotation, isError: isErrorForQuotation, error: errorForQuotation } = useFindQuotationQuery(id);
    const [lead, setLead] = useState({ name: '', email: '', phone: '' });
    const [serviceProvider, setServiceProvider] = useState({ name: '', email: '', phone: '' });
    const { data: estimateData, isLoading: isLoadingForEstimate, isError: isErrorForEstimate, error: errorForEstimate } = useGetEstimateListQuery();
    const { data: currencies, isLoading: isLoadingForCurrency } = useGetCurrenciesQuery();
    const { data: quotationStatus, isLoading: isLoadingForQuotationStatus } = useGetQuotationStatusQuery();

    useEffect(() => {
        if (!isLoadingForQuotation && !isErrorForQuotation && !isLoadingForCurrency && !isLoadingForQuotationStatus) {
            setLead({ name: quotationData?.response?.estimate?.lead?.name, email: quotationData?.response?.estimate?.lead?.email, phone: quotationData?.response?.estimate?.lead?.phone });
            setServiceProvider({ name: quotationData?.response?.estimate?.serviceProvider?.name, email: quotationData?.response?.estimate?.serviceProvider?.email, phone: quotationData?.response?.estimate?.serviceProvider?.phoneNo });
            setSymbol(quotationData?.response?.currency?.symbol ?? 'USD');
            setLoading(false);
        }
    }, [quotationData, isLoadingForQuotation, isErrorForQuotation, isLoadingForQuotationStatus, isLoadingForCurrency])

    const validationSchema = Yup.object().shape({
        estimateId: Yup.string().required('Estimate ID is required'),
        place: Yup.string().required('Place is required'),
        description: Yup.string().required('Description is required'),
        validTimeDay: Yup.number().required('Valid time in days is required'),
        gstPercentage: Yup.number(),
        title: Yup.string().required('Title is required'),
        date: Yup.date().required('Date is required'),
        quotationDetails: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().required('Description is required'),
                developmentTime: Yup.number().required('Development Time is required').positive('Development Time must be a positive number').integer(),
                chargePerHour: Yup.number().required('Charge Per Hour is required').positive('Charge Per Hour must be a positive number').integer(),
            })
        )
    });


    const handleSubTotal = (values) => {
        return values?.quotationDetails.reduce((acc, cur) => acc + Number(cur.total), 0);
    };

    const gstTotal = (values) => {
        const gstPercentage = Number(values.gstPercentage / 100);
        const gstTotal = Number(handleSubTotal(values) * gstPercentage);
        return gstTotal.toFixed(2);
    };

    const allTotal = (values) => {
        const alltotal = Number(Number(gstTotal(values)) + Number(handleSubTotal(values)));
        return alltotal.toFixed(2);
    };

    const handleEstimatesOnChange = (e, setFieldValue) => {
        const estimate = estimateData?.response?.filter((val) => e.target.value === val.id);
        if (estimate) {
            setLead({
                name: estimate[0].lead?.name ?? '_',
                email: estimate[0].lead?.email ?? '_',
                phone: estimate[0].lead?.phone ?? '_'
            });

            setServiceProvider({
                name: estimate[0].serviceProvider?.name ?? '_',
                email: estimate[0].serviceProvider?.email ?? '_',
                phone: estimate[0].serviceProvider?.phoneNo ?? '_'
            });

            setFieldValue('estimateId', e.target.value);
        }
    }

    const quotationDetailsTotal = (id, values) => {
        const details = values.quotationDetails.filter((val) => val.id === id).map((val) => {
            val.total = ((Number(val.developmentTime) * 40) * Number(val.chargePerHour));
            return val;
        });
        handleSubTotal();
        return details[0]?.total ?? 0;
    }

    const handleSubmit = async (values) => {
        try {
            values.userId = 1;
            const updateQuotationData = await updateQuotation(values);
            if (updateQuotationData && updateQuotationData?.error) {
                toast.error(updateQuotationData?.error?.data?.message);
            } else if (updateQuotationData) {
                toast.success(updateQuotationData.data?.message);
                navigate('/quotation');
            }

        } catch (error) {
            toast.error('Something went wrong, Please try again later!');
            console.error('Login failed', error);
        } finally {
            // setSubmitting(false);
        }
    };

    const handleRemoveItem = (id, arrayHelpers) => {
        arrayHelpers.remove(id);
    };

    const currencySymbolSet = (currencyId, setFieldValue) => {
        setFieldValue('currencyId', currencyId)
        const findCurrency = currencies.response?.find(val => val.id === currencyId);
        setSymbol(findCurrency?.symbol ?? 'USD');
    }

    const onImageChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        setFieldValue('signature', file);
        setImagePreview(URL.createObjectURL(file));
    };


    if (loading) {
        return <Loading />;
    }

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="">
                            <h1 className="m-0 badge badge-dark">Edit Quotation</h1>
                        </div>
                        <div className="">
                            <Link to="/quotation" className="btn btn-dark"> <ArrowBackIcon /> Back</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-dark p-3 m-3">
                <div className="card-header">
                    <h3 className="card-title">Quotation</h3>
                </div>
                {isLoadingForQuotation ? (
                    <div>Loading...</div>
                ) : errorForQuotation ? (
                    <div>Error: {errorForQuotation?.message}</div>
                ) : (
                    <Formik
                        initialValues={{
                            id: quotationData?.response?.id,
                            estimateId: quotationData?.response?.estimate?.id || '',
                            userId: quotationData?.response?.userId || '',
                            name: quotationData?.response?.name || '',
                            place: quotationData?.response?.place || '',
                            description: quotationData?.response?.description || '',
                            validTimeDay: quotationData?.response?.validTimeDay || '',
                            gstPercentage: quotationData?.response?.gstPercentage || 0,
                            date: quotationData?.response?.date || '',
                            quotationDetails: quotationData?.response?.quotationDetails || [],
                            currencyId: quotationData?.response?.currency?.id || 1,
                            quotationStatusId: quotationData?.response?.quotationStatus?.id || 1,
                            signature: quotationData?.response?.signature,
                            title: quotationData?.response?.title || ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => handleSubmit(values)}>
                        {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                            <Form>
                                <Box className="card-body" sx={{ padding: "15px 0" }}>
                                    <Grid container spacing={2}>

                                        <Grid item md={12} xs={12}>
                                            <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                                <Typography variant="h6" gutterBottom align="center">
                                                    Quotation
                                                </Typography>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={3}>
                                                        <Field
                                                            component={CustomDatePicker}
                                                            label="Date"
                                                            name="date"
                                                            onChange={(date) => setFieldValue('date', date)}
                                                            fullWidth
                                                        />
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="status-label">Status</InputLabel>
                                                            <Field
                                                                label="Status"
                                                                name="quotationStatusId"
                                                                as={Select}
                                                                labelId="status-label"
                                                                onChange={(event) => setFieldValue('quotationStatusId', event.target.value)}
                                                            >
                                                                {quotationStatus?.response?.map((val, index) => (
                                                                    <MenuItem key={index} value={val.id}>{val.name}</MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={2}>
                                                        <Field
                                                            fullWidth
                                                            label="Valid For (Days)"
                                                            name="validTimeDay"
                                                            variant="outlined"
                                                            type="number"
                                                            as={TextField}
                                                            error={touched.validTimeDay && Boolean(errors.validTimeDay)}
                                                            helperText={touched.validTimeDay && errors.validTimeDay}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={2}>
                                                        {isLoadingForEstimate ? (
                                                            <div>Loading...</div>
                                                        ) : isErrorForEstimate ? (
                                                            <div>Error: {errorForEstimate?.message}</div>
                                                        ) : (
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-select-small-label" sx={touched.estimateId && Boolean(errors.estimateId) ? { color: "hwb(353deg 2% 13%)" } : {}}>Estimates</InputLabel>
                                                                <Field
                                                                    label="Estimates"
                                                                    name="estimateId"
                                                                    as={Select}
                                                                    labelId="demo-select-small-label"
                                                                    id="demo-simple-select"
                                                                    onChange={(event) => {
                                                                        handleEstimatesOnChange(event, setFieldValue);
                                                                    }}
                                                                    error={touched.estimateId && Boolean(errors.estimateId)}
                                                                    helperText={touched.estimateId && errors.estimateId}
                                                                >
                                                                    {estimateData?.response?.map((val, index) => (
                                                                        <MenuItem key={index} value={val.id}>{val.name}</MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </FormControl>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                                <Field
                                                                    fullWidth
                                                                    label="Quotation Title"
                                                                    name="title"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    as={TextField}
                                                                    error={touched.title && Boolean(errors.title)}
                                                                    helperText={touched.title && errors.title}
                                                                />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="currency-label">Currency</InputLabel>
                                                            <Field
                                                                label="Currency"
                                                                name="currencyId"
                                                                as={Select}
                                                                disabled={true}
                                                                labelId="currency-label"
                                                                onChange={(event) => currencySymbolSet(event.target.value, setFieldValue)}
                                                            >
                                                                {currencies?.response?.map((val, index) => (
                                                                    <MenuItem key={index} value={val.id}>{val.name} ({val.symbol})</MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                            </Box>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                                <Typography variant="h6" gutterBottom align="center">
                                                    Service Provider
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField fullWidth label="Name" variant="outlined" value={serviceProvider.name} readonly />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField fullWidth label="Email Address" variant="outlined" type="text" value={serviceProvider.email} readonly />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField fullWidth label="Phone Number" variant="outlined" value={serviceProvider.phone} readonly />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                                <Typography variant="h6" gutterBottom align="center">
                                                    Client Details
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField fullWidth label="Name" variant="outlined" value={lead.name} readonly />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField fullWidth label="Email Address" variant="outlined" type="text" value={lead.email} readonly />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField fullWidth label="Phone Number" variant="outlined" value={lead.phone} readonly />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                                <Typography variant="h6" gutterBottom align="center">
                                                    Project Description
                                                </Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Field name="description">
                                                            {({ field }) => (
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={field.value}
                                                                    onChange={(event, editor) => setFieldValue('description', editor.getData())}
                                                                />
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name="description" component="div" style={{ color: 'red' }} />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                                <Typography variant="h6" gutterBottom align="center">
                                                    Development Time & Cost Details
                                                </Typography>
                                                <FieldArray name="quotationDetails">
                                                    {arrayHelpers => (
                                                        <div>
                                                            {values.quotationDetails.map((val, index) => (
                                                                <Grid container spacing={2} key={val.id} sx={{ my: 1 }}>
                                                                    <Grid item xs={12}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={1}>
                                                                                {index === 0 ? (
                                                                                    <Grid item xs={12}>
                                                                                        <button
                                                                                            className="btn btn-sm"
                                                                                            onClick={() => arrayHelpers.push({
                                                                                                id: values.quotationDetails.length + 1,
                                                                                                description: '',
                                                                                                developmentTime: 0,
                                                                                                chargePerHour: 0,
                                                                                                total: 0
                                                                                            })}
                                                                                        ><AddIcon /> Add More</button>
                                                                                    </Grid>
                                                                                ) : (
                                                                                    <Grid item xs={12}>
                                                                                        <button className="btn btn-sm" onClick={() => handleRemoveItem(index, arrayHelpers)}><DeleteIcon /> Delete</button>
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                            <Grid item xs={1}></Grid>
                                                                            <Grid item xs={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    label="Description"
                                                                                    name={`quotationDetails.${index}.description`}
                                                                                    variant="outlined"
                                                                                    as={TextField}
                                                                                    error={touched.quotationDetails?.[index]?.description && Boolean(errors.quotationDetails?.[index]?.description)}
                                                                                    helperText={touched.quotationDetails?.[index]?.description && errors.quotationDetails?.[index]?.description}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    label="Development Time (in weeks)"
                                                                                    name={`quotationDetails.${index}.developmentTime`}
                                                                                    variant="outlined"
                                                                                    type="number"
                                                                                    as={TextField}
                                                                                    error={touched.quotationDetails?.[index]?.developmentTime && Boolean(errors.quotationDetails?.[index]?.developmentTime)}
                                                                                    helperText={touched.quotationDetails?.[index]?.developmentTime && errors.quotationDetails?.[index]?.developmentTime}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    label="Charge Per Hour"
                                                                                    name={`quotationDetails.${index}.chargePerHour`}
                                                                                    variant="outlined"
                                                                                    type="number"
                                                                                    as={TextField}
                                                                                    error={touched.quotationDetails?.[index]?.chargePerHour && Boolean(errors.quotationDetails?.[index]?.chargePerHour)}
                                                                                    helperText={touched.quotationDetails?.[index]?.chargePerHour && errors.quotationDetails?.[index]?.chargePerHour}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Cost - Excluding GST"
                                                                                    variant="outlined"
                                                                                    type="text"
                                                                                    value={`(${symbol}) ${quotationDetailsTotal(val.id, values)}`}
                                                                                    readonly
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </div>
                                                    )}
                                                </FieldArray>

                                                <Grid container spacing={2} sx={{ mt: 4 }}>
                                                    <Grid item xs={12} sx={{ my: 2 }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={8}></Grid>
                                                            <Grid item xs={2}>
                                                                <h4>Sub Total :</h4>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Box sx={{ border: '1px solid grey', borderRadius: '5px', p: 2 }}>
                                                                    <b>({symbol})</b> {handleSubTotal(values)}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={2}>
                                                                <Box sx={{ border: '1px solid grey', p: 1 }}>
                                                                    {imagePreview ? (
                                                                        <img src={imagePreview} alt="Signature Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                    ) : (
                                                                        values.signature && (
                                                                            <img src={`${API_URL}${values.signature}`} alt="signature" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                        )
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={6}></Grid>
                                                            <Grid item xs={2}>
                                                                <h4>GST (%):</h4>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Field
                                                                    fullWidth
                                                                    label="GST"
                                                                    name="gstPercentage"
                                                                    variant="outlined"
                                                                    type="number"
                                                                    as={TextField}
                                                                    error={touched.gstPercentage && Boolean(errors.gstPercentage)}
                                                                    helperText={touched.gstPercentage && errors.gstPercentage}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Box sx={{ border: '1px solid grey', borderRadius: '5px', p: 2 }}>
                                                                    <b>({symbol})</b> {gstTotal(values)}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={2} sx={{ mt: 1 }}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={2}>
                                                                <div className="file-input">
                                                                    <label htmlFor="signature" className="file-label">Upload Signature</label>
                                                                    <input
                                                                        id="signature"
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={(event) => onImageChange(event, setFieldValue)}
                                                                    />
                                                                    <ErrorMessage name="signature" component="div" className="error-message" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Field
                                                                    fullWidth
                                                                    label="Place"
                                                                    name="place"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    as={TextField}
                                                                    error={touched.place && Boolean(errors.place)}
                                                                    helperText={touched.place && errors.place}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}></Grid>
                                                            <Grid item xs={2}>
                                                                <h4>Total :</h4>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Box sx={{ border: '1px solid grey', borderRadius: '5px', p: 2 }}>
                                                                    <b>({symbol})</b> {allTotal(values)}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <button
                                        type="submit"
                                        className="btn btn-dark mt-2"
                                        disabled={isSubmitting}
                                    >{isSubmitting ? 'Updating...' : 'Update'}</button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
}
export default QuotationEdit;